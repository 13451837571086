.slider__arrow {
    display: none;
    width: 4.17vw;
    height: 4.17vw;

    cursor: pointer;
}


@media( max-width: 1200px ) {
    .slider__arrow {
        width: 13.33vw;
        height: 13.33vw;
    }
}