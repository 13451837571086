* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    list-style-type: none;
}

a {
    text-decoration: none;

    color: #000;
}